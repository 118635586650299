import React from 'react'

import classes from './PromoVideo.module.css'

const promoVideo = () => {
    return (
        <div className={classes.Div}>
            <iframe title='Add Play With CleatSheet App' className={classes.Video} src="https://www.youtube.com/embed/sTUJisKx91s" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
        </div>
    )
}

export default promoVideo