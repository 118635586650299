import React, { Component, Fragment} from 'react';

import classes from './Layout.module.css'

import Toolbar from '../../components/Toolbar/Toolbar';

class Layout extends Component {
    state = {
        showSidebar: false
    }

    sidebarClosedHandler = () => {
        this.setState( { showSideDrawer: false } );
    }

    sidebarToggleHandler = () => {
        this.setState( ( prevState ) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        } );
    }

    render () {
        return (
            <Fragment>
                <Toolbar></Toolbar>
                <main className={classes.MainSection}>
                    {this.props.children}
                </main>
            </Fragment>
        )
    }
}


export default Layout