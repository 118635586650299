import React from 'react';

// import classes from './ErrorModal.module.css';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const SignedUpModal =  ( props ) => {

    return(
      <Modal centered show={props.show} onHide={props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Uh Oh, Something Went Wrong...</Modal.Title>
        </Modal.Header>
        <Modal.Body classes='text-align: justify'>Something doesn't seem right... Please contact us if this keeps happening and we can take a look.</Modal.Body>
        <Modal.Footer>
          <Button type='reset' variant="outline-success" onClick={props.closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default SignedUpModal;