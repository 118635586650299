import React from 'react';

import classes from './Card.module.css';

const Card = ( props ) => {

    return(
        <div className={classes.Card}>
            <div className={classes.CardHeading}>
                <h3 className={classes.CardHeadingText}>{props.heading}</h3>
                <div className={classes.CardSeperator}/>
            </div>
            <div className={classes.CardBody}>
                <p className={classes.CardBodyText}>{props.body}</p>
            </div>
        </div>
    )
}

export default Card