import React from 'react'

import classes from './Toolbar.module.css';

import Logo from '../Logo/Logo';
import AccountLogo from '../AccountLogo/AccountLogo';
import NavigationItems from '../NavigationItems/NavigationItems';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import LongLogo from '../../assets/Logo/Long/LongLogoWhiteClear.png';

const accountPopover = (
    <Popover id="accountPopover">
      <Popover.Title as="h3">User Accounts</Popover.Title>
      <Popover.Content>
          <ul>
              <li>
              You will be able to sign up for a CleatSheet user account <strong>for free</strong>.
              </li>
              <li>
              Once created, your team administrator can add you to the team roster.
              </li>
          </ul>
      </Popover.Content>
    </Popover>
  );

const toolbar = (props) => (
    <header className={classes.Toolbar}>
        <div className={classes.Logo} onClick={window.location.reload}>
            <Logo/>
        </div>
        {false ? <nav className={classes.DesktopOnly}><NavigationItems /></nav> : null }
        <div className={classes.AccountLogo}>
            <OverlayTrigger trigger="hover" placement="bottom" overlay={accountPopover}>
                <button className={classes.AccountLogo}>
                <AccountLogo/>
                </button>
            </OverlayTrigger>
        </div>
    </header>
);

export default toolbar