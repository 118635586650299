import React from 'react';
import classes from './CardIcon.module.css';

const CardIcon = ( props ) => {
    return(
        <div className={classes.ImageBackground}>
            <img src={props.image} width ='150px' alt={props.alternate} className={classes.Image}/>
        </div>
    )
}

export default CardIcon;