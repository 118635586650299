import React, {Component, Fragment} from 'react'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse';

import Backdrop from '../../components/Backdrop/Backdrop';

import classes from './SignupJumbotron.module.css'

class SignupJumbotron extends Component {
    state = {
        jumboOpen: true
    }

    CloseJumboHandler = () => {
        this.setState({
            jumboOpen: false
        })
    }

    RegistedAndCloseJumboHandler = () => {
        this.CloseJumboHandler();
        document.getElementById("signUpForm").scrollIntoView({behavior: 'smooth', block: 'center'});
        document.getElementById('fName').focus({preventScroll: true});
    }
    render() {
        return(
                <Fragment>
                    <Collapse in={this.state.jumboOpen}>
                        <Jumbotron style={{position: 'fixed',zIndex: '101'}}>
                            <button type="button" className={classes.CloseButton} onClick={() => {this.CloseJumboHandler()}}>X</button>
                            <h1>Coming Soon...</h1>
                            <p>
                                Cleatsheet is currently in development. We are targeting a <strong>Winter release</strong>. To be kept up to date with our progress please register for our mailing list.
                            </p>
                            <p>
                                <Button type="button" variant="outline-success" onClick={() => {this.RegistedAndCloseJumboHandler()}}>Register</Button>
                                <Button type="button" variant="outline-secondary" style={{marginLeft: '15px'}} onClick={() => {this.CloseJumboHandler()}}>Close</Button>
                            </p>
                        </Jumbotron>
                    </Collapse>
                    <Backdrop style={{zIndex: '1'}} show={this.state.jumboOpen}/>
                </Fragment>
        )
    }
}

    export default SignupJumbotron