import React from 'react';

import classes from './Logo.module.css'

import whiteLogo from '../../assets/Logo/Square/WhiteClearSquareLogo.png'

const logo = (props) => (
    <div className={classes.Logo} style={{height: props.height}}>
        <a href="#top"><img src={whiteLogo} alt="WhiteLogo" /></a>
    </div>
)

export default logo