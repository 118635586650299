import React, { Fragment } from 'react';

import {Row, Col} from 'react-bootstrap'

import SocialButtons from '../SocialButtons/SocialButtons'

import classes from './Footer.module.css';

const Footer = () => {

    return (
        <Fragment>
            <footer className={classes.Footer}>
              <Row>
                <Col>
                  <p style={{color:'#2fac66'}}>© {new Date().getFullYear()} CleatSheet</p>
                </Col>
                <Col style={{textAlign: 'center'}}>
                  <SocialButtons/>
                </Col>
                <Col style={{textAlign: 'right'}}>
                  <a style={{color:'#2fac66'}} href='mailto:cleatsheetapp@gmail.com'>Contact us</a>
                </Col>
              </Row>
            </footer>
        </Fragment>
    )
}

export default Footer