import React from 'react';
import classes from './Segment.module.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const Segment = ( props ) => {
    return(
        <div className={props.secondary ? classes.SecondarySegment : classes.Segment}>
            <Container>
              <Row>
                    <div className={classes.HeaderContainer}>
                        <h1 className={classes.Header}>{props.heading}</h1>
                    </div>
                    <div className={props.secondary ? classes.SecondarySeperator : classes.Seperator}></div>
                </Row>
                    {props.children}
            </Container>   
        </div>
    )
}

export default Segment