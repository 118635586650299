import React from 'react';

import classes from './AccountLogo.module.css'

import whiteLogo from '../../assets/AccountIcon.png'

const accountLogo = (props) => (
    <div className={classes.AccountLogo} style={{height: props.height}}>
        <img src={whiteLogo} alt="WhiteLogo" />
    </div>
)

export default accountLogo