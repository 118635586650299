import React, { Fragment } from 'react';

import classes from './SocialButtons.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faInstagram, faYoutubeSquare, faLinkedin} from '@fortawesome/free-brands-svg-icons';

const SocialButtons =  ( props ) => {

    return(
        <Fragment>
            <a href='https://www.facebook.com/CleatSheetApp/' className={classes.Facebook}> <FontAwesomeIcon icon={faFacebookSquare} size="2x"/></a>
            <a href='https://twitter.com/CleatSheetApp' className={classes.Twitter}><FontAwesomeIcon icon={faTwitterSquare} size="2x"/></a>
            <a href='https://www.instagram.com/cleatsheetapp/' className={classes.Instagram}><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
            <a href='https://www.youtube.com/channel/UCSZ6nC6Rk_hhg-wZ3RDTSew/' className={classes.Youtube}><FontAwesomeIcon icon={faYoutubeSquare} size="2x" /></a>
            <a href='https://www.linkedin.com/company/cleatsheet/' className={classes.Linkedin}><FontAwesomeIcon icon={faLinkedin} size="2x" /></a>
        </Fragment>
    )
}

export default SocialButtons