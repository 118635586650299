import React from 'react';

import classes from './NavigationItems.module.css';

import NavigationItem from './NavigationItem/NavigationItem';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const teamZonePopover = (
    <Popover id="teamZonePopover">
      <Popover.Title as="h3">Team Zone</Popover.Title>
      <Popover.Content>
          <ul>
              <li>
                    Here you will have access to your team's statistics.
              </li>
              <li>
                    Administrators will be able to export data to Hudl too.
              </li>
          </ul>
      </Popover.Content>
    </Popover>
  );

  const individualZonePopover = (
    <Popover id="teamZonePopover">
      <Popover.Title as="h3">Individual Zone</Popover.Title>
      <Popover.Content>
          <ul>
              <li>
                    Here you will have access to your personal statistics.
              </li>
              <li>
                    Create your own dashboard of your most impressive stats.
              </li>
          </ul>
      </Popover.Content>
    </Popover>
  );

const navigationItems = (props) => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/">Home</NavigationItem>

        <OverlayTrigger trigger="hover" placement="bottom" overlay={teamZonePopover}>
            <div>
                <NavigationItem Disabled={true} link="/">Team Zone</NavigationItem>
            </div>
        </OverlayTrigger>

        <OverlayTrigger trigger="hover" placement="bottom" overlay={individualZonePopover}>
            <div>
                <NavigationItem Disabled={true} link="/">Individual Zone</NavigationItem>
            </div>
        </OverlayTrigger>

    </ul>
);


export default navigationItems