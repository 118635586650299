import React, {Component, Fragment} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

import Layout from './hoc/Layout/Layout';
import SignupForm from './containers/SignupForm/SignupForm';
import SignupJumbotron from './containers/SignupJumbotron/SignupJumbotron';
import PromoVideo from './components/PromoVideo/PromoVideo';
import Footer from './components/Footer/Footer';
import Devices from './assets/Devices.png';
import Card from './components/Card/Card';
import Segment from './components/Segment/Segment';
import CardIcon from './components/CardIcon/CardIcon';

import Trophy from './assets/football-trophy.svg';
import Signals from './assets/american-football-signals.svg';
import TvProgram from './assets/football-tv-program.svg';
import Cheerleader from './assets/american-football-cheerleader-jump.svg';
import Numbers from './assets/american-football-scores-numbers.svg';
import XandOs from './assets/game-planning.svg';
import Helmet from './assets/football-helmet.svg';
import Jersey from './assets/football-t-shirt-with-number-83.svg';
import Stadium from './assets/stadium-cylinder.svg';

class App extends Component {
  state={AdditionalFeaturesExtended: false}

  SeeMoreButtonClickhandler(){
    const oldState = this.state.AdditionalFeaturesExtended;
        const newState = !oldState;
        this.setState({AdditionalFeaturesExtended: newState})
  }

  RegisterInterestButtonHandler(){ 
    document.getElementById("signUpForm").scrollIntoView({behavior: 'smooth', block: 'center'});
    document.getElementById('fName').focus({preventScroll: true});
  }
  render() {
    var seeMoreOrLessLabel;

    if(this.state.AdditionalFeaturesExtended) {
      seeMoreOrLessLabel='▲ See Less Features ▲';
    } else {
      seeMoreOrLessLabel='▼ See More Features ▼';
    }

    return(
      <Fragment>
        <Layout>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <SignupJumbotron/>
        </div>
          <Container style={{marginTop: '20px', marginBottom: '25px'}}>
            <Row>
              <Col>
                <h1 style={{color: '#575756', fontFamily: '\'Open Sans\', sans-serif', fontWeight: '600', fontSize: '3.5rem', margin: '20px 0'}}>Professional stats.<br/>Amateur effort.</h1>
                <img src={Devices} width='80%' alt='CleatSheet can be used from the web, iPhone, iPad and Android phones'/>
              </Col>
              <Col>
                <SignupForm/>
              </Col>
            </Row>
          </Container>
          <Segment heading='About CleatSheet'>
            <Row>
              <Col>
                <CardIcon image={Signals} alternative='First Down Chains'/>
                <Card heading='Capture Data' body={["Use CleatSheet's mobile app to easily record game data in real time from your sideline."]}/>
              </Col>
              <Col>
                <CardIcon image={TvProgram} alternative='Football TV Program'/>
                <Card heading='Analyze Statistics' body={["Access and amend your data from the website after the game.",<br key='i'/>,<br key='j'/>,"Generate statistics to help your team improve."]}/>
              </Col>
              <Col>
                <CardIcon image={Trophy} alternative='Winners Trophy'/>
                <Card heading='Export To Hudl' body={["Effortlessly tag your game film with CleatSheet's data in minutes."]}/>
              </Col>
            </Row>
          </Segment>
          <Segment secondary={true} heading='Demo'>
            <Col style={{paddingBottom: '50px', textAlign: 'center'}}>
              <p style={{color:  'white', fontFamily: "'Open Sans', sans-serif", fontSize: 'large'}}>See how easy it is to record an offensive play using the CleatSheet app.</p>
              <PromoVideo/>
            </Col>
          </Segment>
          <Segment heading='Additional Features'>
            <Row>
              <Col>
                <CardIcon image={Numbers} alternative='football field numbers'/>
                <Card heading='Complexity' body={["Decide what level of detail you want to capture.",<br key='i'/>,<br key='j'/>,"Capture anything from just team rush or passing yard totals to professional-level individual stats."]}/>
              </Col>
              <Col>
                <CardIcon image={Helmet} alternative='football helmet'/>
                <Card heading='Smart Ordering' body ={["Effortlessly find your regular ball-carriers, pass-catchers and tacklers after each play.",<br key='i'/>,<br key='j'/>,"Check out 'Smart Player Ordering' in our demo video."]}/>
              </Col>
              <Col>
                <CardIcon image={XandOs} alternative='game planning diagram'/>
                <Card heading='Scout Mode' body={["Why limit data capture just to your own games?",<br key='i'/>,<br key='j'/>,"Scout opposition using CleatSheet's platform."]}/>
              </Col>
            </Row>
              <Collapse in={this.state.AdditionalFeaturesExtended}>
                <Row>
                  <Col>
                    <CardIcon image={Stadium} alternative='football stadium'/>
                    <Card heading='Offline Mode' body={["Don't let a lack of connectivity stop you.",<br key='i'/>,<br key='j'/>,"Capture data in offline mode and the mobile app will upload when a connection returns."]}/>
                  </Col>
                  <Col>
                    <CardIcon image={Jersey} alternative='football jersey'/>
                    <Card heading='Manage Roster' body={["Track player availablity in preparation to games.",<br key='i'/>,<br key='j'/>,"Publish your depth chart with CleatSheet too."]}/>
                  </Col>
                  <Col>
                    <CardIcon image={Cheerleader} alternative='cheerleader'/>
                    <Card heading='Social Media' body={["Boost your team's social media with a professional look.",<br key='i'/>,<br key='j'/>,"Generate graphics from your statistics to post."]}/>
                  </Col>
                </Row>
              </Collapse>
              <Row>
                <button style={{backgroundColor: 'Transparent', border: 'none', margin: '0 auto', marginBottom: '20px', color:  'white', fontFamily: "'Open Sans', sans-serif", fontSize: 'large'}} onClick={() => this.SeeMoreButtonClickhandler()}>{seeMoreOrLessLabel}</button>
              </Row>
          </Segment>
          <Segment secondary={true} heading='Find Out More'>
            <Container style={{textAlign: 'center'}}>
              <Row>
                <Col>
                  <p style={{color:  'white', fontFamily: "'Open Sans', sans-serif", fontSize: 'large'}}>Still interested?</p>
                </Col>
              </Row>
              <Row style={{paddingBottom: '20px'}}>
                <Col>
                  <Button type="button" variant="outline-light" onClick={() => {this.RegisterInterestButtonHandler()}}>Register Interest</Button>
                  <Button as='a' type="button" variant="outline-light" style={{marginLeft: '15px'}} href='mailto:cleatsheetapp@gmail.com'>Contact Us</Button>
                </Col>
              </Row>
            </Container>
          </Segment>
          <Container>
            <Footer/>
          </Container>
        </Layout>
      </Fragment>
    )
  }
}

export default App;
