import React from 'react';

// import classes from './SignedUpModal.module.css';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const SignedUpModal =  ( props ) => {

    return(
      <Modal centered show={props.show} onHide={props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>You're All Signed Up!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Thank you for registering your interest with CleatSheet! We will be in touch soon!</Modal.Body>
        <Modal.Footer>
          <Button type='reset' variant="outline-success" onClick={props.closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default SignedUpModal;